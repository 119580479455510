<template>
  <div class="content-container"> 

    <PageTitle title="Algemene instructies" class="header">
      <template #extra-content>
        <button
          type="button"
          class="pink-button create-button"
          @click="createNew"
        >
          {{ ItemTextAddButton[selectedItem] }}
        </button>
      </template>
    </PageTitle>

    <div class="horizontal-layout">
      <MenuHorizontalItem text="Instructies" :active="(selectedItem === 0)" @click="setItem(0)"></MenuHorizontalItem>
      <MenuHorizontalItem text="Bijlagen" :active="(selectedItem === 1)" @click="setItem(1)"></MenuHorizontalItem>
      <MenuHorizontalItem text="Instructievideo's" :active="(selectedItem === 2)" @click="setItem(2)"></MenuHorizontalItem>
    </div>

    <InstructionsView v-if="(selectedItem === 0)" :create-instruction="createInstruction">Instructions</InstructionsView>
    <AttachmentsView v-else-if="(selectedItem === 1)" :create-attachment="createAttachment">Attachments</AttachmentsView>
    <VideosView v-else :create-video="createVideo">Videos</VideosView>


    <BasicModal v-if="isBasicVersionDisplayed"
                :button-right-disabled="!FileData">
      <template #content>
        <file-dropbox
          :allowed-mime-types="allowedMimes"
          :style-preset="'small'"
          :callback="UploadFile">

        </file-dropbox>
      </template>
    </BasicModal>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import MenuHorizontalItem from '@/components/elements/instructions/MenuHorizontalItem'
import { computed, ref } from '@vue/reactivity'
import InstructionsView from './InstructionsView.vue'
import AttachmentsView from './AttachmentsView.vue'
import VideosView from './VideosView.vue'
import { useRouter } from 'vue-router'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'

import { useStore } from 'vuex'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import FileDropbox from '../../../components/elements/FileDropbox.vue'
import { CREATE_ATTACHMENT, CREATE_VIDEOS, GET_ATTACHMENTS, GET_VIDEOS } from '@/store/modules/cms/actions'

export default {
  name: 'InstructionsMain',
  components: {
    PageTitle,
    MenuHorizontalItem,
    InstructionsView,
    AttachmentsView,
    VideosView,
    BasicModal,
    FileDropbox
  },
  setup(props) {
    const router = useRouter()
    const store = useStore()

    const allowedMimes = computed(() => activeModal.value === MODAL_NAMES.CREATE_ATTACHMENT ?
      ['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'] :
      ['video/mp4'])

    const activeModal = computed(() => store.getters.getActiveBasicModal)

    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.CREATE_ATTACHMENT ||
      activeModal.value === MODAL_NAMES.CREATE_VIDEO
    )

    const modalController = ref(new ModalController())

    const selectedItem = ref(0)
    const FileData = ref(0)
    const ItemTextAddButton = ['Instructies toevoegen', 'Voeg bijlage toe', 'Voeg video toe']

    function setItem(item) {
      selectedItem.value = item
    }


    function createInstruction() {
      router.push({ name: ROUTE_NAMES_CMS.CREATE_INSTRUCTIONS })
    }

    function createAttachment() {
      modalController.value.setModal(
        BASIC_MODAL_CONTENT.UPLOAD_FILE,
        UploadAttachment,
        null,
        null,
        MODAL_NAMES.CREATE_ATTACHMENT)
    }

    function UploadAttachment() {
      store.dispatch(CREATE_ATTACHMENT, { mediaFile: FileData.value }).then(() => {
        modalController.value.resetActiveModal()
        store.dispatch(GET_ATTACHMENTS, {})
      })
    }

    function createVideo() {
      modalController.value.setModal(
        BASIC_MODAL_CONTENT.UPLOAD_VIDEO,
        UploadVideo,
        null,
        null,
        MODAL_NAMES.CREATE_VIDEO)
    }

    function UploadVideo() {
      store.dispatch(CREATE_VIDEOS, { mediaFile: FileData.value }).then(() => {
        modalController.value.resetActiveModal()
        store.dispatch(GET_VIDEOS, {})
      })
    }

    function UploadFile(data) {
      FileData.value = data[0]
    }

    function createNew() {
      //create based on which page we are on
      if (selectedItem.value === 0) {
        createInstruction()
      } else if (selectedItem.value === 1) {
        createAttachment()
      } else {
        createVideo()
      }
    }

    return {
      selectedItem,
      ItemTextAddButton,
      createInstruction,
      createAttachment,
      createVideo,
      createNew,
      UploadFile,
      isBasicVersionDisplayed,
      setItem,
      allowedMimes,
      FileData
    }
  },
  template: 'InstructionsMain'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.horizontal-layout {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.header {
  margin-bottom: rem(40);
}
</style>
