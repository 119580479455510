<template>
  <div class="content-container">
    <div v-if="attachmentData.length > 0" class="header">
      <h1 > {{ $t('INSTRUCTIONS.ATT_OVERVIEW') }} Bijlagen overzicht</h1>
    </div>
    <CustomTable
      :options="AttachmentTableOptions"
      :table-data="attachmentData.items"
      table-class="class-Instructions"
      :empty-state-data="emptyStateData"
      :search-function="manageSearch"
      :less-padding="true"

      :hide-search="true"
    >
      <template #right-size-info>
        <p> {{ totalItems + $t('INSTRUCTIONS.ATT_TOTAL') }} </p>
      </template>
      <template #order="props" >
        <div>{{ props.rowData.id }}</div>
      </template>
      <template #name="props">
        <a class="downloadable" @click="downloadItem(props.rowData.fileName,props.rowData.name)">{{ props.rowData.name }}</a>
      </template>
      <template #size="props">
        <div>{{ props.rowData.fileSize }}</div>
      </template>
      <template #createdAt="props">
        <div>{{ new Date(props.rowData.createdAt).toLocaleDateString('nl') + ' ' + new Date(props.rowData.createdAt).toLocaleTimeString('nl') }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="'Verwijderen'"
                        :icon="require('../../../assets/icons/icn_delete.svg')"
                        custom-class="button-red"
                        class="action"
                        @click="preDeleteAttachment(props.rowData)"/>
        </div>
      </template>
    </CustomTable>
    <BasicModal v-if="isBasicVersionDisplayed"/>

  </div>
</template>

<script>
import BasicModal from '@/components/elements/basicModal/BasicModal'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'


import { computed, ref } from '@vue/reactivity'
import { GET_ATTACHMENTS, DELETE_ATTACHMENT } from '@/store/modules/cms/actions'
import { useStore } from 'vuex'

import CustomTable from '@/components/table/CustomTable'
import ActionButton from '@/components/table/ActionButton'
import { getTableOptions, VIDEO_AND_ATTACHMENTS } from '@/utils/helpers/customTable/CustomTableHelper'
import { createEmptyTableData } from '@/utils/helpers/customTable/EmptyTableHelper'
import Axios from 'axios'

export default {
  name: 'AttachmentsView',
  components: {
    CustomTable,
    ActionButton,
    BasicModal,
  },
  props: {
    createAttachment: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const store = useStore()

    const activeModal = computed(() => store.getters.getActiveBasicModal)

    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.DELETE_ATTACHMENT
    )


    const attachmentData = computed(() => store.getters.getAttachments)



    const emptyStateData = createEmptyTableData('ATTACHMENTS', props.createAttachment, require('@/assets/img/img_addfile@2x.png'))

    manageSearch()

    function manageSearch(payload) {
      if (!payload) {
        payload = {}
      }
      getInstructions(payload)
    }

    function getInstructions(payload) {
      return store.dispatch(GET_ATTACHMENTS, payload)
    }

    const modalController = ref(new ModalController())


    function preDeleteAttachment(attachmentData) {
      modalController.value.setModal(BASIC_MODAL_CONTENT.DELETE_FILE(attachmentData.name), deleteAttachment, null, attachmentData, MODAL_NAMES.DELETE_ATTACHMENT)
    }

    function deleteAttachment(attachmentData) {
      store.dispatch(DELETE_ATTACHMENT, attachmentData.id).then(response => {
        manageSearch()
        modalController.value.resetActiveModal()
      })
    }

    function downloadItem(url, label) {
      Axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    }

    return {
      attachmentData,
      emptyStateData,
      AttachmentTableOptions: getTableOptions(VIDEO_AND_ATTACHMENTS),

      isBasicVersionDisplayed,
      preDeleteAttachment,
      manageSearch,
      downloadItem
    }
  },
  template: 'Instructions'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.downloadable{
  color: var(--blue_link);
  font-weight: 500;
  cursor: pointer;
}
</style>
