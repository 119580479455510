<template>
  <div class="content-container">
    <div v-if="instructionData.length > 0" class="header">
      <h1 >Instructies overzicht</h1>
      <p class="instruction-count"> {{ instructionData.length }} Instructies totaal</p>
    </div>
    <!-- <Search search-title="Zoeken op titel,partnerlink of tag" :manage-search="handleSearch"/> -->
    <CustomTable
      :options="instructionsTableOptions"
      :search-function="manageSearch"
      :hide-search="true"
      :less-padding="true"
      :on-reorder="(id,newIndex) => reorderInstruction(id,newIndex)"
      :table-data="instructionData.items"
      :empty-state-data="emptyStateData"
      table-class="class-Instructions"
    >
      <template #order="props" >
        <div>{{ props.rowData.id }}</div>
      </template>
      <template #title="props">
        <div>{{ trimTitle(props.rowData.title) }}</div>
      </template>
      <template #createdAt="props">
        <div>{{ new Date(props.rowData.createdAt).toLocaleDateString('nl') + ' ' + new Date(props.rowData.createdAt).toLocaleTimeString('nl') }}</div>
      </template>
      <template #status="props">
        <div v-if="props.rowData.status == 1" class="published status"> {{ $t("PUBLISHED").toUpperCase() }} </div>
        <div v-else class="concept status">{{ $t("CONCEPT").toUpperCase() }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="'Verwijderen'"
                        :icon="require('../../../assets/icons/icn_trash_white.svg')"
                        custom-class="button-red"
                        class="action"
                        @click="preDeleteInstruction(props.rowData)"/>
          <ActionButton :text="'Bewerken'"
                        :icon="require('../../../assets/icons/icn_pencil_white.svg')"
                        custom-class="button-blue"
                        class="action"
                        @click="editInstruction(props.rowData)"/>
        </div>
      </template>
    </CustomTable>
    <BasicModal v-if="isBasicVersionDisplayed"/>

  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import { GET_INSTRUCTIONS, DELETE_INSTRUCTION, REORDER_INSTRUCTION } from '@/store/modules/cms/actions'
import ActionButton from '@/components/table/ActionButton'
import CustomTable from '@/components/table/CustomTable'
import { createEmptyTableData } from '@/utils/helpers/customTable/EmptyTableHelper'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'

import { getTableOptions, INSTRUCTIONS } from '@/utils/helpers/customTable/CustomTableHelper'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'


export default {
  name: 'InstructionsView',
  components: {
    CustomTable,
    ActionButton,
    BasicModal,
  },
  props: {
    createInstruction: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()

    const modalController = ref(new ModalController())
    const activeModal = computed(() => store.getters.getActiveBasicModal)

    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.DELETE_INSTRUCTION
    )

    function manageSearch(payload) {
      if (!payload) {
        payload = {}
      }
      getInstructions(payload)
    }

    function trimTitle(title, maxLength = 50) {
      if (title.length <= maxLength) {
        return title
      }
      return title.substring(0, 50).trim() + '...'
    }


    function getInstructions(payload) {
      return store.dispatch(GET_INSTRUCTIONS, payload)
    }

    const emptyStateData = createEmptyTableData('INSTRUCTIONS', props.createInstruction, require('@/assets/icons/icn_instruction.svg'))

    const instructionData = computed(() => store.getters.getInstructions)

    function editInstruction(data) {
      router.push({ name: ROUTE_NAMES_CMS.EDIT_INSTRUCTIONS, params: { id: data.id } })
    }

    function preDeleteInstruction(data) {
      modalController.value.setModal(
        BASIC_MODAL_CONTENT.DELETE_INSTRUCTION_MODAL(data.title),
        DeleteInstruction,
        null,
        data,
        MODAL_NAMES.DELETE_INSTRUCTION)
    }

    function DeleteInstruction(data) {
      store.dispatch(DELETE_INSTRUCTION, data.id).then(result => {
        if (result) {
          manageSearch()
          modalController.value.resetActiveModal()
        }
      })
    }

    function reorderInstruction(id, newIndex) {
      const payload = {
        /*questionId: questionId,
        id: id,
        questionIndex: newIndex*/
      }
      store.dispatch(REORDER_INSTRUCTION, payload).then(response => {
        getInstructions()
      })
    }

    return {
      instructionData,
      isBasicVersionDisplayed,
      instructionsTableOptions: getTableOptions(INSTRUCTIONS),

      preDeleteInstruction,
      emptyStateData,
      editInstruction,
      manageSearch,
      reorderInstruction,
      trimTitle
    }
  },
  template: 'Instructions'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

h1 {
  margin: rem(30) 0 rem(20) 0;
}

.cell-custom-table {
  padding: 0 0 0 0;
}

.instruction-count{
  margin: auto 0 rem(25) auto;
  color: gray;
  text-align: right;
}

.header{
  display: flex;
}

.delete-Button{

}

.edit-Button{

}

.status{
  font-weight: 800;
}

.published{
  color: rgb(81,84,104)
}

.concept{
 color: rgb(123,150,179)
}
</style>
